<template>
    <router-view :key="$route.fullPath"/>
</template>

<script>

export default {
  name: 'Bet'
}
</script>

<style scoped></style>
